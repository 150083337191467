import Badge from 'atoms/Badge';
import HeadingHR from 'atoms/HeadingHR';
import IconCheckMark from 'atoms/SvgIcons/IconCheckMark.svg';
import Text from 'atoms/Text';
import { useAddressContext } from 'components/context/addressContext';
import CardOption from 'molecules/CardOption';
import LinkNavigator from 'molecules/LinkNavigator';
import PropTypes from 'prop-types';
import Modal from '../../atoms/Modal';
import BodyForModal from './BodyForModal';
import HeaderForModal from './HeaderForModal';
import ModalForAddAddress from './ModalForAddAddress';
import ModalForServicablePincode from './ModalForServicablePincode';
import { useRouter } from 'next/router';
import { handleAnalyticEventHandleAddressUpdate } from 'utils/ga-analytic';
import googleAnalytics from "../../assets/mo_engage/order_flow_ga.json"
import { useEffect, useState } from 'react';
import Button from 'atoms/Button';
import ModalForAddResellerPincode from './ModalForAddResellerPincode';

const ModalForPincodeChange = ({ className, setPincodeValue, modalTitle, isReseller=false, patientDetails, showPinCodeChnage=false }) => {
    const { showAddress, setShowAddress, showBillingAddress, setShowBillingAddress, isOpenPincodeModal, isChangeMessage, setIsOpenPincodeModal, addressList, handleModalStateChange, register,
        handleSubmit, modalForServicablePincode, isRemoveDisabled, activeAddress, updateAddressData, setUpdateAddressData, isEditCheckDisabled, setIsEditCheckDisabled, deliveryAddressIndex, billingAddressIndex,
        setValue, setIsModalOpen,
        getValues,
        clearErrors, errors, control, stateList, setAddressList, isDefaultDeliveryAddress, isDefaultBillingAddress,
        setError,
        watch, handleAddressChange, changeAddress, handleDeliveryAddress, onCreateAddress, isShowAddressModal, handleModalForServicablePincode, handleBillingAddress, listOfAddress, setStateCode, tradeName, setTradeName, userType, onCreatePincode,
        addressAlertMsg, setAddressAlertMsg,
        isShowPincodeModal, setIsShowPincodeModal, setIsForReseller
     } = useAddressContext()
    const colorProperty = userType == 1 ? 'grey400' : "red"
    const analyticEntity = googleAnalytics.entity;
    const router = useRouter()
    const [selectedAddressId, setSelectedAddressId] = useState(null); // Added state to track selected address ID
    const [showChangeMessage, setShowChangeMessage] = useState(false); // State to show change message
    const [isDisable, setIsDisable] = useState(true); // button disable when changed message

    const onCreateAndUpdateAddress = async (data) => {
        const response = await onCreateAddress(data);
        if (response?.status) {
            if (addressList.length == 0) {
                setIsOpenPincodeModal(false);
            }
        } else {
            setIsOpenPincodeModal(true);
        }
    }
    useEffect(() => {
        const defaultAddress = addressList.find(address => showBillingAddress ? address.is_billing === 1 : address.is_default === 1);
        if (defaultAddress) {
            setSelectedAddressId(defaultAddress.id);
        }
    }, [addressList, showBillingAddress]);

    const onSaveAndClose = () => {
        const selectedAddress = addressList.find(address => address.id === selectedAddressId);
        if (selectedAddress) {
            handleAddressChange(null, selectedAddress); // Update address only when the "Save and Close" button is clicked
            setIsOpenPincodeModal(false); // Close the modal
            setIsDisable(true)
            if (isReseller) {
                window.location.reload();
            }
        }
    }
    const handleRadioChange = (data) => {
        setSelectedAddressId(data.id);
        setShowChangeMessage(true); // Show change message when address is changed
        setIsDisable(false)
    }

    useEffect(()=>{
        if(isReseller){
            setIsForReseller(true)
        }
    },[isReseller])
    const onCreatePincodeData = async (data) => {
        const response = await onCreatePincode(data);
        if (response?.status == true) { 
                setIsShowPincodeModal(false);
                window.location.reload()
        } else {
            setIsShowPincodeModal(true);
        }
    }

    return (<>{isOpenPincodeModal &&
            <Modal
                type="modalTypeZero"
                containerClass={`${isOpenPincodeModal || 'hidden'}`}
                className={`${className} max-w-full md:max-w-171.5 w-full mb-0 md:mb-7.5 customScrollbar`}
                handlerModalClose={handleModalStateChange}
                isModalOpen={isOpenPincodeModal}
                containerPadding='px-0'
                containerAlignment='items-end md:items-center'
                dataAutomation='change-address'
            >

                <div className='flex flex-col min-h-[300px] h-min max-h-[calc(100vh_-_60px)] md:max-h-185 relative customScrollbar'>

                    <HeaderForModal
                        className='flex-shrink-0'
                        isCloseButton={true}
                        handlerModalClose={(event) => handleModalStateChange({ event })}
                        dataAutomation='medicine'
                    >{modalTitle}</HeaderForModal>

                    {showChangeMessage && ( // Show change message if state is true
                        (<div className='bg-semantic-error_light flex items-center align-center pl-4 p-2'>
                            <Text type="bodySmall" color='text-semantic-error_base' className="text-semantic-error_base" fontWeight={400}>
                                Medicine availability and total amount will change as per stock availability at the new pincode. Please review your cart before placing the order.
                            </Text>
                        </div>)
                    )}
                    <div className='relative overflow-y-auto flex-grow customScrollbar'>

                        <BodyForModal type="bodyType1" className="px-0 pb-4 md:px-8 md:pb-7 ">
                            {addressList?.map((data) => {
                                return (
                                    <CardOption
                                        className='p-5 pb-4 mb-2'
                                        key={data.id}
                                        // handlerRadioChange={(e) => handleAddressChange(e, data)}
                                        value={data.id}
                                        // isSelected={showBillingAddress ? data.is_billing === 1 : data.is_default === 1}
                                        isSelected={selectedAddressId === data.id || (selectedAddressId === null && (showBillingAddress ? data.is_billing === 1 : data.is_default === 1))} // Check if the address is selected
                                        radioGroupName="addresses"
                                        dataAutomation='address-options'
                                        handlerRadioChange={() => handleRadioChange(data)} // Set selected address ID on radio button change

                                    >
                                        <div className='flex-grow'>
                                            <div className='flex justify-between'>
                                                <Text className="mb-1" type="body" color='text-primary1-500'
                                                    fontWeight={600}>{data.contact_person_name}</Text>
                                                <div className='flex items-center'>
                                                    {(data.gstVerified == 1) && <Badge className="border-0 mr-2" type="success" >
                                                        <IconCheckMark className="w-3  mr-2" type="checkMark" /> GST Verified </Badge>}
                                                    {!showBillingAddress && (data.is_default == 1) && <Badge type='dark' className='whitespace-nowrap'>Default Shipping</Badge>}&nbsp;
                                                    {(data.is_billing == 1) && <Badge type='dark' className='whitespace-nowrap'>Default Billing</Badge>}
                                                </div>
                                            </div>
                                            <Text className="mb-1" type="bodySmall" color='text-gray-600' fontWeight={400}>
                                                Address:  {data?.address || '-'} <br />
                                                Landmark: {data?.landmark || '-'} <br />
                                                {data?.city}, {data?.State?.name} - {data?.pin_code} <br />
                                                Phone number: {data?.contact_person_phone}<br />
                                                Alternate Phone number: {data?.alt_mobile || '-'} <br />
                                                GSTIN:{data?.gst || <span style={{ color: `${colorProperty}` }}> Not Added</span>} <br />
                                                Legal entity name: {data?.legalEntityName || <span style={{ color: `${colorProperty}` }}> Not Added</span>}<br />
                                                Trade name: {data?.tradeName || <span style={{ color: `${colorProperty}` }}> Not Added</span>}<br />

                                            </Text>

                                        </div>
                                    </CardOption>
                                )
                            })}
 
                            <HeadingHR className='mt-6 mb-3'>or add new address</HeadingHR>

                            <LinkNavigator
                                onClick={() => { handleAnalyticEventHandleAddressUpdate({ eventName: analyticEntity?.Address_Update?.event_name?.Address_Add, router }); setIsModalOpen(true) }}
                                GroupHeading="Add new address"
                                className="py-3 my-0 lg:mb-0 w-full"
                                labelClass="text-sm font-semibold text-gray-900"
                                dataAutomation='add-new-address'
                            />

                            {isReseller && showPinCodeChnage && <><HeadingHR className='mt-6 mb-3'>or add a pincode</HeadingHR>

                            <LinkNavigator
                                onClick={() => { handleAnalyticEventHandleAddressUpdate({eventName: analyticEntity?.Address_Update?.event_name?.Address_Add, router}); setIsShowPincodeModal(true)}}
                                GroupHeading="Add a pincode"
                                className="py-3 my-0 lg:mb-0 w-full"
                                labelClass="text-sm font-semibold text-gray-900"
                                dataAutomation='add-new-address'
                            /></>}
                        </BodyForModal>
                    </div>
                        {addressList?.length > 0 && <div className='bg-scroll mx-auto py-2 flex justify-center relative'>
                            <Button variant={"Primary"} onClick={onSaveAndClose} disabled={isDisable} className='rounded-lg h-10.5 line-clamp-2 w-[250px] px-15'>Update</Button>
                        </div>}
                </div>

            </Modal >}
        {isShowAddressModal &&
            <ModalForAddAddress
                label={"Add address"}
                isModalOpen={isShowAddressModal}
                handleModalStateChange={() => setIsModalOpen(false)}
                onSubmit={onCreateAndUpdateAddress}
                handleSubmit={handleSubmit}
                setIsModalOpen={setIsModalOpen}
                register={register}
                errors={errors}
                stateList={stateList}
                setValue={setValue}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                setAddressList={setAddressList}
                billingAddress={addressList}
                deliveryAddress={addressList}
                handleBillingAddress={handleBillingAddress}
                control={control}
                isDefaultBillingAddress={isDefaultBillingAddress}
                isDefaultDeliveryAddress={isDefaultDeliveryAddress}
                handleDeliveryAddress={handleDeliveryAddress}
                isEditCheckDisabled={isEditCheckDisabled}
                setStateCode={setStateCode}
                setTradeName={setTradeName}
                tradeName={tradeName}
                isAddressModal={true} 
                isForReseller={isReseller}
                editPageData={patientDetails}
                addressAlertMsg={addressAlertMsg}
                setAddressAlertMsg={setAddressAlertMsg}
            />
        }
        {
            isShowPincodeModal && showPinCodeChnage && <ModalForAddResellerPincode
            label={"Add a pincode"}
            isModalOpen={isShowPincodeModal}
            handleModalStateChange={() => setIsShowPincodeModal(false)}
            onSubmit={onCreatePincodeData}
            handleSubmit={handleSubmit}
            setIsModalOpen={setIsShowPincodeModal}
            register={register}
            errors={errors}
            stateList={stateList}
            setValue={setValue}
            watch={watch}
            setError={setError}
            clearErrors={clearErrors}
            setAddressList={setAddressList}
            billingAddress={addressList}
            deliveryAddress={addressList}
            handleBillingAddress={handleBillingAddress}
            control={control}
            isDefaultBillingAddress={isDefaultBillingAddress}
            isDefaultDeliveryAddress={isDefaultDeliveryAddress}
            handleDeliveryAddress={handleDeliveryAddress}
            isEditCheckDisabled={isEditCheckDisabled}
            setStateCode={setStateCode}
            setTradeName={setTradeName}
            tradeName={tradeName}
            isAddressModal={false} 
            isForReseller={isReseller}
            />
        }
        {
            modalForServicablePincode && <ModalForServicablePincode
                isModalOpen={modalForServicablePincode}
                handleModalStateChange={handleModalForServicablePincode}
                paragraph={"Sorry. NirogStreet is yet to bring its services to your pincode. Please change your address and try again."}
                changeAddress={changeAddress}
            />
        }
    </>);
}

ModalForPincodeChange.propTypes = {
    className: PropTypes.string,
    isModalOpen: PropTypes.bool,
    handleModalStateChange: PropTypes.func,
}

export default ModalForPincodeChange

